
.contact-container {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

.contact-form {
    width: 80%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

.github-container {
    display: flex;
    flex-flow: row nowrap;
    width: 50%;
    align-content: center;
    justify-content: center;
}

.github-logo {
    width: 100px;
}

.github-mark {
    width: 40px;
    height: 40px;
}

.linkedin-container {
    margin: 30px 0;
}

.linkedin-logo {
    width: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.linkedin-mark {
    width: 100px;
}

.email-add {
    margin-top: -40px;
    margin-bottom: 20px;
    padding-top: 0;
    color: #000;
}