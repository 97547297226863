.email-form {
    border: 1px solid black;
    background: #303633;
    padding: 20px;
    color: #fff;
    display: flex;
    flex-flow: column wrap;
    width: 80%;
}

.message-box {
    height: 200px;
    resize: none;
}

.email-form button {
    font-family: "Fauna One", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS";
    font-size: 1rem;
    padding: 0;
    align-self: center;
    border: none;
    border-radius: 6px;
    width: 30%;
    margin-top: 10px;
    background: #fff;
    color: #303633;
}

.email-form-header {
    text-align: center;
}