.portfolio-main {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

.about-section {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
 }

 .about-section p {
     padding: 0 20px;
 }
 
.profile-image {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    width: 100%;
}

.profile-image img {
    width: 100%;
}

.story-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.story-section {
    width: 42%;
    padding: 20px;
}

.skills-list {
    background: #000;
    width: 30%;
    color: #fff;
    display: flex;
    flex-flow: column wrap;
    padding: 0 20px;
    align-items: center;
}

.skills-list ul {
    padding: 0 20%;
    margin-bottom: 10%;
}

.skills-list h3 {
    font-size: 1.6em;
    margin: 5% 0;
}

.about-section h3 {
    margin-bottom: -1%;
}

@media all and (max-width: 800px) {
    .portfolio-main h3, .portfolio-main h4 {
        padding: 10px;
    }

    .skills-list {
        width: 70%;
    }

    .story-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    }

    .story-section {
    width: 80%;
    padding: 20px;
    align-self: center;
    }
}