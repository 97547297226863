.projects-container {
    padding: 20px;
}

.projects-container p, .projects-container h3 {
    padding: 10px;
}

.projects-list {
    list-style: none;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    justify-content: center;
}

.project-frame {
    background: #303633;
    color: #fff;
    width: 40%;
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
}

.single-project {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.single-project p {
    margin: 10px;
}

.project-url-list {
    width: 90%;
    padding: 0;
}

.project-url-list li {
    list-style: none;
}

.project-url-list a {
    color: #fff;
    padding: 10px;
}

.thumbnail {
    width: 250px;
}

.thumbnail img {
    width: 100%;
}

.links-text {
    font-size: 1.2rem;
    padding: 0;
}

@media all and (max-width: 800px) {
    .projects-list {
        width: 100%;
    }
    .projects-container {
        width: 90vw;
        padding: 0;
    }
    .project-frame {
        width: 100%;
    }
}