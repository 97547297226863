body {
  margin: 0;
  background: #bec5c2;
  overflow-x: hidden;
}

h1,
h2 {
  font-family: "Fauna One", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

h3,
h4,
h5 {
  font-family: "Fauna One", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

nav,
label,
p {
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  font-weight: 400;
}