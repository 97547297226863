header {
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    text-align: center;

    background: #303633;
    padding-bottom: 2%;

    color: #fff;
}

header h1 {
    font-size: 2.4rem;
}

header h2 {
    font-size: 1.9rem;
}