nav {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

nav a {
    font-size: 1.0rem;
    background: #000;
    color: #fff;
    text-decoration: none;
    margin-bottom: -4.5%;
    padding: 6px 10px;

    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
}