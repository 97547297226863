body {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    width: 100vw;
}

.app-main {
    background: #fff;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
}

main {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

.drop-box {
    display: flex;
    flex-flow: column wrap;
    background: grey;
    width: 100vw;
    align-items: center;
}

footer {
  background: #303633;
  color: #ffffff;
  text-align: center;
  margin: 0% auto;
  padding: 1px 0;
  height: 30px;
  width: 100%;
  margin-top: 30px;
}

@media all and (min-width: 800px) {
    .drop-box {        
    max-width: 800px;
    }

    .app-main {
        width: 99%;
    }
}